import React, { useState } from 'react';

import { Link, NavLink, useLocation } from 'react-router-dom';

import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';

import MobileMenu from './MobileMenu';
import LinkButton from '../LinkButton';

import logo from '../../../assets/images/logo.png';
import classes from './styles.module.scss';

const navLinks = [
  { title: 'services', href: '#services' },
  { title: 'process', href: '#process' },
  { title: 'technologies', href: '#technologies' },
  { title: 'clients', href: '#clients' },
];

export default function Header() {
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

  const location = useLocation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });

  const scrollIntoView = (id) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        inline: 'nearest',
      });
    }
  };

  return (
    <header
      className={classNames(classes.Header, {
        [classes.mobileMenuVisible]: isMobileMenuVisible,
      })}
    >
      <Link
        to="/"
        onClick={() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }}
      >
        <img src={logo} alt="Logo" className={classes.logo} />
      </Link>
      <div className={classes.navContainer}>
        <nav className={classes.mainNav}>
          <ul className={classes.linksList}>
            {navLinks.map((link) => (
              <li key={link.title} className={classes.mainLink}>
                <NavLink
                  onClick={() => scrollIntoView(link.href.slice(1))}
                  to="/"
                  className={() =>
                    location.hash === link.href ? classes.active : undefined
                  }
                >
                  <span>{link.title}</span>
                </NavLink>
              </li>
            ))}
            <li>
              <LinkButton onClick={() => setIsMobileMenuVisible(false)} />
            </li>
          </ul>
        </nav>
        <button
          type="button"
          className={classNames(classes.burgerButton, {
            [classes.open]: isMobileMenuVisible,
          })}
          onClick={() => {
            setIsMobileMenuVisible((prevState) => !prevState);
          }}
        >
          <span />
          <span />
          <span />
          <span />
        </button>
      </div>
      {isTabletOrMobile && (
        <MobileMenu
          show={isMobileMenuVisible}
          handleClose={() => setIsMobileMenuVisible(false)}
          navLinks={navLinks}
          location={location}
          scrollIntoView={scrollIntoView}
        />
      )}
    </header>
  );
}
